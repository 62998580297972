@import './_variables';
@import './_mixin';
@import './_reset';
@import './_base';

/*--------------------------------------------------
Loading
--------------------------------------------------*/
.l-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $accent;
  position: fixed;
  top: 0;
  left: 0;
  user-select: none;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: 0.7s ease;

  &.is-active {
    pointer-events: all;
    visibility: visible;
    opacity: 100%;
  }

  &_wrapper {
    position: relative;
  }

  &_illust {
    position: absolute;
    width: 130px;
    height: auto;
    top: calc(50% - 65px);
    left: calc(50% - 65px);

    @include mq-up(md) {
      width: 170px;
      top: calc(50% - 85px);
      left: calc(50% - 85px);
    }

    @include mq-up(lg) {
      width: 200px;
      top: calc(50% - 100px);
      left: calc(50% - 100px);
    }
  }
}

/*--------------------------------------------------
Swiperカスタマイズ
--------------------------------------------------*/
.swiper {
  position: relative;
  overflow: visible;

  .swiper-pagination-bullet {
    background-color: rgba($black, 0.6);
    height: 3px;
    width: 30px;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    background-color: $primary;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: calc(50% - (50px / 2));
    height: 80px;
    width: 80px;
    background-color: $primary;
    border-radius: 50%;
  }

  .swiper-button-prev {
    left: calc((4vw + 40px) * -1);

    @include mq-up(lg) {
      left: calc((25px + 40px) * -1);
    }
  }

  .swiper-button-next {
    right: calc((4vw + 40px) * -1);

    @include mq-up(lg) {
      right: calc((25px + 40px) * -1);
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: $secondary;
    font-size: 15px;
    font-weight: 700;
  }

  .swiper-button-prev::after {
    margin-left: 20px;
  }

  .swiper-button-next::after {
    margin-right: 20px;
  }
}

/*--------------------------------------------------
基本レイアウト系
--------------------------------------------------*/
.l-section {
  position: relative;
  padding: $space-l 0;
}

.l-container {
  position: relative;
  padding: 0 $px-inner-m;

  @include mq-up(lg) {
    padding: 0 $space-4;
  }
}

/*--------------------------------------------------
コンポーネント
--------------------------------------------------*/
//h2-タイトル
.c-title-h2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-bottom: $space-1;

  &_icon {
    display: block;
    width: 100px;
    height: 100px;

    &.ellipse {
      background: url(../img/00_common/title-icon-ellipse.png) no-repeat left center/contain;
    }

    &.snapper {
      width: 150px;
      height: 200px;
      background: url(../img/00_common/title-icon-snapper.png) no-repeat left center/contain;
    }

    &.camera {
      width: 200px;
      height: 150px;
      background: url(../img/00_common/title-icon-camera.png) no-repeat left center/contain;
    }

    &.round {
      background: url(../img/00_common/title-icon-round.png) no-repeat left center/contain;
    }
  }

  .title-en {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: rgba($black, 0.6);
  }

  .title-ja {
    font-size: $size-4;
    color: rgba($black, 0.6);
  }
}

//アイコン付きリンク
.c-link-tsubaki {
  .text {
    display: inline-block;
    padding-right: $space-7;
    color: rgba($white, 0.6);
    font-size: $size-4;
    letter-spacing: 0.1em;
    transition: all 0.3s;
  }

  .icon {
    display: inline-block;
    line-height: 1rem;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    .text {
      color: $white;
      transition: all 0.3s;
    }

    .icon {
      opacity: 1;
      transition: all 0.3s;
    }
  }
}

//ボタン 箱型アロータイプ
.c-btn-boxArrow {
  position: relative;
  display: block;
  width: 300px;
  margin: 0 auto;
  overflow: hidden;

  &:hover {
    .c-btn-boxArrow_box {
      .top,
      .bottom {
        &::before {
          transform: translateX(0);
          transition: all 0.3s;
        }
      }
    }

    .c-btn-boxArrow_text {
      top: -17px;
      transition: all 0.3s;

      .visible {
        opacity: 0;
        transition: all 0.3s;
      }

      .hidden {
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }

  &_box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px;

    .top,
    .bottom {
      position: relative;
      width: 250px;
      height: 50px;
      border: solid $primary;
      background-color: $white;
      overflow: hidden;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $primary;
        transform: translateX(-95%);
        transition: all 0.3s;
      }
    }

    .top {
      border-width: 1px 1px 0 1px;
      transform: skew(30deg);
    }

    .bottom {
      border-width: 0 1px 1px 1px;
      transform: skew(-30deg);
    }
  }

  &_text {
    position: absolute;
    top: 15px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s;

    .visible {
      position: relative;
      display: inline-block;
      opacity: 1;
      color: $primary;
      transition: all 0.3s;
    }

    .hidden {
      position: relative;
      display: inline-block;
      color: $white;
      opacity: 0;
      transition: all 0.3s;
    }
  }
}

/*--------------------------------------------------
メイン領域の共通スタイル
--------------------------------------------------*/
.page-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 2;

  @include mq-up(lg) {
    left: calc(((100vw - 400px) / 2) + 10%);
    width: 400px;
  }

  @include mq-up(xl) {
    left: calc(((100vw - 400px) / 2) + 15%);
  }

  &_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: $white;
    overflow: hidden;
    margin: 0;
  }
}

.section-lead {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  padding-bottom: $space-1;

  .c-title-h2 {
    margin: 0 auto;
  }

  &_text {
    text-align: justify;

    &.center {
      text-align: center;
    }

    &_break {
      display: inline-block;
      padding: $space-4 0;
    }
  }
}

.section-devider {
  position: relative;
  width: 100%;

  &.right-downward {
    position: absolute;
    top: -1px;
  }

  &.vertically-reversed {
    img {
      rotate: X 180deg;
    }
  }

  &.horizontally-reversed {
    img {
      rotate: Y 180deg;
    }
  }

  &.mt-negative-200 {
    margin-top: -55vw;

    @include mq-up(lg) {
      margin-top: -200px;
    }
  }
}

/*--------------------------------------------------
ヘッダーのスタイル
--------------------------------------------------*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  @include mq-up(lg) {
    left: calc(((100vw - 400px) / 2) + 10%);
    width: 400px;
  }

  @include mq-up(xl) {
    left: calc(((100vw - 400px) / 2) + 15%);
  }

  &_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: $space-5 $space-4;
  }

  &_logo {
    position: relative;
    width: 80px;
    z-index: 9999;
  }

  &_btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    z-index: 9999;

    @include mq-up(lg) {
      display: none;
    }

    &:hover {
      .header_btn_text {
        .visible {
          top: -15px;
          opacity: 0;
          transition: all 0.3s;
        }

        .hidden {
          top: 0;
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }

    &.is-active {
      .header_btn_text.menu {
        opacity: 0;
        transition: all 0.3s;
      }

      .header_btn_text.close {
        opacity: 1;
        transition: all 0.3s;
      }

      .header_btn_line {
        .first {
          margin-top: 17px;
          transform: rotate(15deg);
          background-color: $white;
          transition: all 0.3s;
        }

        .second {
          margin-top: -1px;
          transform: rotate(-15deg);
          background-color: $white;
          transition: all 0.3s;
        }
      }
    }

    &_text {
      width: 100%;
      text-align: center;
      transition: all 0.3s;

      &.menu {
        position: relative;
        opacity: 1;
      }

      &.close {
        position: absolute;
        top: 0;
        left: 0;
        color: $white;
        opacity: 0;
      }

      .visible,
      .hidden {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 50px;
        text-align: center;
        font-size: $size-5;
      }

      .visible {
        top: 0;
        opacity: 1;
        transition: all 0.3s;
      }

      .hidden {
        top: 15px;
        opacity: 0;
        transition: all 0.3s;
      }
    }

    &_line {
      position: relative;
      width: 100%;
      margin-top: 20px;

      .first,
      .second {
        position: relative;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $primary;
        margin-top: 10px;
        transition: all 0.3s;
      }
    }
  }

  //ナビメニュー
  &-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translateX(100%);
    transition: ease 0.4s;
    z-index: 9998;
    visibility: hidden;
    opacity: 0;

    &.is-active {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    &.is-hidden {
      display: none;
    }

    &_inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: $accent;
      padding: 0 $space-1;
    }

    &_title {
      font-size: $size-1;
      font-weight: 500;
      color: $primary;
      letter-spacing: 0.1em;
    }

    &_list {
      padding-top: $space-1;

      &-item {
        padding-bottom: $space-5;
      }
    }
  }
}

/*--------------------------------------------------
メインビジュアル領域のスタイル
--------------------------------------------------*/
.kv {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  z-index: 0;

  @include mq-up(lg) {
    height: 600px;
  }

  &_inner {
    position: relative;
    width: 100%;
    padding-top: $space-xl;
    z-index: 1;
  }

  &_title {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: $space-1;
    z-index: 1;

    @include mq-up(md) {
      padding-top: $space-1;
      padding-left: 120px;
    }

    @include mq-up(lg) {
      padding-top: 0;
      padding-left: $space-1;
    }

    span {
      position: relative;
      display: inline-block;
      height: fit-content;
      background-color: $white;
      font-size: $size-4;
      writing-mode: vertical-rl;
      line-height: 40px;
      letter-spacing: 0.2em;
      padding: $space-5 0;
      box-shadow: 10px 10px 20px rgba($black, 0.3);

      @include mq-up(md) {
        font-size: $size-3;
      }

      &:first-child {
        margin-left: $space-5;
        z-index: 10;
      }

      &:last-child {
        margin-top: $space-4;
        z-index: 9;
      }
    }
  }

  &_content {
    position: absolute;
    top: 21vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 0;

    @include mq-up(md) {
      top: 15vh;
    }

    @include mq-up(lg) {
      top: 150px;
    }

    &-top {
      position: relative;
      left: 0;
      display: flex;
      align-items: flex-start;

      &_img {
        &:first-child {
          width: 50%;
          height: 30vh;
          margin-top: 33%;
          padding: 0 $space-3;

          @include mq-up(md) {
            height: 50vh;
            margin-top: 20%;
          }

          @include mq-up(lg) {
            height: 200px;
            margin-top: 38%;
          }
        }

        &:last-child {
          width: 50%;
          height: 45vh;

          @include mq-up(md) {
            height: 60vh;
          }

          @include mq-up(lg) {
            height: 300px;
          }
        }
      }
    }

    &-bottom {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding-right: $space-2;
      margin-top: -13vh;

      @include mq-up(md) {
        margin-top: -22vh;
      }

      @include mq-up(lg) {
        margin-top: -80px;
      }

      &_img {
        position: relative;
        width: 65%;
        height: 20vh;

        @include mq-up(md) {
          width: 55%;
          height: 30vh;
        }

        @include mq-up(lg) {
          width: 65%;
          height: 150px;
        }
      }
    }
  }

  &_bg {
    position: absolute;
    top: 80px;
    right: 0;
    height: calc(100% - 80px);
    z-index: -1;
  }
}

/*--------------------------------------------------
コンセプト領域のスタイル
--------------------------------------------------*/
.concept {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba($secondary, 0.2) url(../img/00_common/main-bg01.png) repeat left top/contain;

  &_lead {
    position: relative;
    padding-top: $space-xl;
    background: $white;
  }

  &_devider {
    position: relative;
    display: block;
    width: 100%;

    &_img {
      position: relative;
      width: 140%;
      padding-bottom: 200px;
      margin-left: -30%;
      overflow-x: hidden;
      z-index: 2;

      @include mq-up(md) {
        padding-bottom: 300px;
      }

      @include mq-up(lg) {
        width: 550px;
        padding-bottom: 200px;
        margin-left: -100px;
      }
    }

    &_body {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      z-index: 1;
      overflow-x: hidden;

      &_spacer {
        position: relative;
        display: block;
        width: 100%;
        height: 200px;
        background: $white;

        @include mq-up(md) {
          height: 300px;
        }

        @include mq-up(lg) {
          height: 200px;
        }
      }

      &_img {
        position: relative;
        width: 100%;
      }
    }

    &_bg {
      position: absolute;
      bottom: 0;
      right: -30%;
      width: 80%;
      overflow-x: hidden;
      z-index: 0;
    }
  }

  &_content {
    position: relative;
    z-index: 0;
    padding: 80px 0 $space-xl;

    &_inner {
      position: relative;
      padding: $space-xl $space-3 $space-1;
      background: $white url(../img/00_common/main-bg01.png) repeat left top/contain;
      border: 5px solid $secondary;
      border-radius: 15px;
      box-shadow: 10px 10px 20px rgba($black, 0.3);
      z-index: 1;
    }

    &_icon {
      position: relative;
      width: 250px;
      margin: -180px auto 0;
    }

    &_text {
      position: relative;
      padding: $space-1 0;
    }

    &_img {
      position: relative;
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

/*--------------------------------------------------
プラン領域のスタイル
--------------------------------------------------*/
.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: $space-l;
  z-index: 1;

  //メインエリア
  &-main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $space-1 0 0;

    &_list {
      position: relative;
      display: flex;
      flex-direction: column;

      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: $space-xl;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &_lead {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .catch {
        display: inline-block;
        text-align: center;
        font-size: $size-3;
        font-weight: 700;
        word-break: keep-all;
        padding-bottom: $space-3;

        .main {
          display: block;
        }

        .sub {
          display: block;
          font-size: $size-4;

          .color-secondary {
            color: $secondary;
          }
        }
      }

      .img {
        width: 100vw;
        margin-left: calc(((100vw - 100%) / 2) * -1);
        margin-right: calc(((100vw - 100%) / 2) * -1);
        margin-bottom: $space-l;

        @include mq-up(lg) {
          width: 400px;
          margin-left: -25px;
          margin-right: -25px;
        }

        &-dual {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-gap: $space-5;
          width: 100%;
          margin-bottom: $space-l;

          &-item {
            grid-column: 6 span;
          }
        }
      }

      .icon {
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 100px;
      }
    }

    &_content {
      position: relative;
      display: flex;
      flex-direction: column;

      .main-visual {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: $space-5;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .sub-banner {
        display: flex;
        flex-direction: column-reverse;
        padding: 20px;
        margin-bottom: $space-1;
        border-radius: 5px;
        border: 1px solid rgba($primary, 0.3);

        &_text {
          display: block;
          margin-top: $space-5;
        }

        &_img {
          display: block;
          width: 100%;
          height: auto;

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }

      .banner {
        display: block;
        width: 100%;
        height: auto;
        margin-top: $space-3;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .title {
        display: inline-block;
        width: fit-content;
        padding: $space-6 $space-3;
        margin-bottom: $space-3;
        background-color: rgba($secondary, 0.7);
        border-radius: 5px;
        font-size: $size-3;
        font-weight: 700;

        &.simple {
          background-color: $gray;
        }

        &.strong {
          font-size: $size-1;
          margin: 0 auto $space-3;
        }

        &.primary-light {
          background-color: #9ab6d8;
        }

        &_extra {
          position: relative;
          padding: $space-6 $space-3;
          margin-bottom: $space-1;
          border-top: 1px solid $primary;
          border-bottom: 1px solid $primary;
          font-size: $size-3;
          font-weight: 700;
          text-align: center;

          &::before {
            position: absolute;
            content: '';
            bottom: 13px;
            left: 0;
            display: block;
            width: 30px;
            height: 30px;
            background: url('/img/03_plan/plan-title-decoration-pattern.webp') no-repeat center center/cover;
            transform: rotate(180deg);
          }

          &::after {
            position: absolute;
            content: '';
            top: 13px;
            right: 0;
            display: block;
            width: 30px;
            height: 30px;
            background: url('/img/03_plan/plan-title-decoration-pattern.webp') no-repeat center center/cover;
          }
        }
      }

      .description {
        padding-bottom: $space-3;
        text-align: justify;
      }

      .priceList {
        position: relative;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray;

        .name {
          display: inline-block;
          width: 100%;
          padding-left: $space-5;
          background-color: $primary;
          color: $white;

          &.disable {
            opacity: 0.5;
          }
        }

        .price {
          display: inline-block;
          padding-bottom: $space-4;
          font-weight: 500;

          span {
            font-size: $size-1;
          }
        }

        .priceDown {
          position: relative;
          display: block;
          width: 100%;
          padding: $space-5 0 $space-4;

          &_inner {
            position: relative;
            display: flex;
            align-items: flex-end;
            width: 100%;
          }

          /*
          &.disable {
            position: relative;
            width: fit-content;
            padding-bottom: 0;
            color: rgba($primary, 0.5);

            &::before {
              position: absolute;
              content: '';
              top: 30px;
              left: 0;
              display: inline-block;
              width: 100%;
              height: 1px;
              background-color: $primary;
            }

            span {
              font-size: $size-2;
            }
          }*/

          .head_title {
            display: inline-block;
            padding-bottom: 3px;
            font-weight: 500;
            line-height: 1;
            word-break: keep-all;
            white-space: nowrap;
          }

          .arrow {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 150px;
            padding: 0 0 0 $space-5;

            &::before {
              position: relative;
              content: '';
              display: block;
              width: 100%;
              height: 5px;
              background-color: $gray;
            }

            &::after {
              position: relative;
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 8px solid transparent;
              border-left: 15px solid $gray;
            }
          }

          .strong {
            font-size: $size-1;
            font-weight: 500;
            line-height: 1;
            color: $accent;
            word-break: keep-all;
            white-space: nowrap;
          }

          .percent {
            display: inline;
            font-weight: 500;
            line-height: 1;
            word-break: keep-all;
            white-space: nowrap;
          }
        }
      }

      .notionList {
        position: relative;
        padding: $space-5 0 $space-1;

        &-item {
          position: relative;
          font-size: $size-5;
          padding-left: 20px;

          &::before {
            position: absolute;
            content: '※';
            top: 0;
            left: 0;
          }
        }

        &.center {
          width: fit-content;
          margin: 0 auto;
        }

        &.plan-content-notion {
          padding: 0;
        }
      }

      .body {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: $space-1;

        &_title {
          display: inline-block;
          padding: 0 $space-6;
          margin-bottom: $space-4;
          background-color: rgba($gray, 0.3);
          border: 1px solid $primary;
          border-radius: 5px;
          font-weight: 700;
        }

        &_list {
          position: relative;
          display: flex;
          justify-content: flex-start;
          margin-bottom: $space-3;

          &-item {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 80px;
            margin-right: $space-6;

            &:last-child {
              margin-right: 0;
            }

            &_icon {
              position: relative;
              width: 100%;
            }

            &_name {
              display: inline-block;
              font-size: $size-6;
              font-weight: 700;
            }

            &_notion {
              position: relative;
              font-size: $size-5;
              padding-left: 20px;
              margin-bottom: $space-3;

              &::before {
                position: absolute;
                content: '※';
                top: 0;
                left: 0;
              }
            }

            &.exclude {
              opacity: 0.3;
            }
          }
        }

        &-highlight {
          &_title {
            position: relative;
            display: inline-block;
            width: fit-content;
            padding: 0 $space-5;
            margin-bottom: $space-5;
            font-size: $size-3;
            font-weight: 700;
            z-index: 0;

            &::before {
              position: absolute;
              content: '';
              bottom: 0;
              left: 0;
              width: 100%;
              height: 15px;
              background-color: #f5c1b5;
              z-index: -1;
            }

            &.center {
              margin: 0 auto $space-5;
            }
          }

          &_list {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: $space-3 0;

            &-item {
              display: flex;
              padding-bottom: $space-5;

              &:last-child {
                padding-bottom: 0;
              }

              &_icon {
                width: 80px;
                padding-right: 10px;
              }

              &_text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: calc(100% - 80px);

                &_name {
                  display: inline-block;
                  font-weight: 700;
                }

                &_link {
                  display: inline-block;

                  i {
                    padding-left: 10px;
                    font-size: $size-3;
                  }
                }
              }
            }
          }
        }
      }

      .option {
        position: relative;
        display: flex;
        flex-direction: column;

        &_title {
          display: inline-block;
          padding: 0 $space-6;
          margin-bottom: $space-4;
          background-color: rgba($gray, 0.3);
          border: 1px solid $primary;
          border-radius: 5px;
          font-weight: 700;

          .color-secondary {
            color: $secondary;
          }
        }

        &_cardList {
          display: flex;
          flex-direction: column;
          margin-bottom: $space-3;

          &-item {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
            margin-bottom: $space-4;
            border-radius: 5px;
            background-color: rgba($secondary, 0.2);

            &_content {
              display: flex;
              flex-direction: column;
              width: calc(100% - 85px);

              &_title {
                display: block;
                margin-bottom: $space-6;
                font-size: $size-5;
                font-weight: 500;
                line-height: 1;
              }

              &_price {
                display: flex;
                align-items: flex-end;
                margin-bottom: $space-5;

                .mark {
                  display: inline-block;
                  padding-right: $space-7;
                  font-size: $size-4;
                  font-weight: 700;
                  line-height: 2.5rem;
                  color: $accent;
                }

                .price {
                  display: inline-block;
                  font-size: $size-1;
                  font-weight: 700;
                  line-height: 1;
                }

                .currency {
                  display: inline-block;
                  padding-right: $space-6;
                  font-size: $size-4;
                  font-weight: 700;
                  line-height: 1;
                }

                .tax {
                  display: inline-block;
                  font-size: $size-4;
                  line-height: 1;
                }
              }

              &_description {
                font-size: $size-5;
                line-height: 1.6;
              }
            }

            &_icon {
              display: block;
              width: 70px;
              margin-right: $space-5;

              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }
        }

        &_list {
          &-item {
            position: relative;
            padding-left: 20px;

            &::before {
              position: absolute;
              content: '・';
              top: 0;
              left: 0;
            }

            &.square {
              &::before {
                content: '◆';
              }
            }

            &.plus {
              padding-bottom: $space-2;

              &::after {
                position: absolute;
                content: '+';
                bottom: 12px;
                left: 50px;
                font-size: $size-3;
                line-height: 1;
              }
            }

            .description {
              display: block;
              padding: $space-7 0 $space-5;
              font-size: $size-5;
            }
          }
        }

        &_img {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-gap: 10px;
          margin: $space-3 0;

          &_text {
            display: flex;
            align-items: center;
            grid-column: 2 span;

            span {
              margin: 0 auto;
              font-size: $size-2;
              font-weight: 700;
              text-align: center;
            }
          }

          &-item {
            grid-column: 5 span;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &_body {
              height: 37vw;

              @include mq-up(lg) {
                height: 140px;
              }
            }

            &_note {
              font-size: $size-5;
            }
          }

          &-vertical {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: $space-3 0;

            &-item {
              position: relative;
              display: flex;
              justify-content: center;
              width: 100%;
              padding-bottom: $space-2;

              &::before {
                position: absolute;
                content: '+';
                bottom: 9px;
                font-size: $size-1;
                font-weight: 700;
                line-height: 1;
              }

              &:last-child {
                padding-bottom: 0;

                &::before {
                  display: none;
                }
              }

              &_body {
                position: relative;
                width: 40vw;
                height: 40vw;

                @include mq-up(lg) {
                  width: 150px;
                  height: 150px;
                }
              }

              &_text {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: calc(100% - 40vw);
                height: 40vw;
                background-color: $pink;
                padding-left: $space-5;

                @include mq-up(md) {
                  padding-left: $space-3;
                }

                @include mq-up(lg) {
                  width: calc(100% - 150px);
                  height: 150px;
                  padding-left: $space-5;
                }

                &_title {
                  font-size: $size-5;
                  font-weight: 700;

                  @include mq-up(md) {
                    font-size: $size-4;
                  }

                  @include mq-up(lg) {
                    font-size: $size-5;
                  }
                }

                &_size {
                  font-size: $size-5;
                }
              }
            }
          }
        }
      }
    }
  }

  //フィーチャーエリア
  &-feature {
    position: relative;
    background-color: $pink;

    &_inner {
      padding: $space-l 0;
    }

    &_title {
      position: relative;
      padding-left: 35px;
      font-size: $size-3;
      font-weight: 700;

      &::before {
        position: absolute;
        content: url(../img/00_common/sakura-icon.svg);
        top: 5px;
        left: 0;
      }
    }

    &_list {
      position: relative;
      padding: $space-1 0;

      &-item {
        position: relative;
        padding-bottom: $space-1;

        &:last-child {
          padding-bottom: 0;
        }

        &_number {
          display: flex;
          flex-direction: column;
          padding-bottom: $space-3;

          .en {
            display: inline-block;
            width: fit-content;
            font-size: $size-6;
            font-weight: 700;
            border-bottom: 1px solid $primary;
          }

          .number {
            display: inline-block;
            width: fit-content;
            font-size: $size-l;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 0.1em;
          }
        }

        &_title {
          display: inline-block;
          width: 100%;
          padding: $space-6 $space-4;
          margin-bottom: $space-3;
          background-color: $white;
          border-radius: 10px;
          font-size: $size-3;
          font-weight: 700;
        }

        &_text {
          padding-bottom: $space-3;
        }

        &_frame {
          position: relative;
          display: flex;
          flex-direction: column;
          padding: $space-5;
          background-color: #f5c1b5;
          border-radius: 10px;

          &_inner {
            display: flex;
            padding-bottom: $space-5;
          }

          &_img {
            width: 120px;
            height: 120px;
          }

          &_text {
            width: calc(100% - 120px);
            padding-left: $space-5;
          }

          &_notion {
            position: relative;
            padding-left: 20px;
            font-size: $size-5;

            &::before {
              position: absolute;
              content: '※';
              top: 0;
              left: 0;
            }
          }
        }

        &_content {
          position: relative;
          display: flex;
          align-items: flex-start;

          &_img {
            width: 110px;
            height: 160px;

            img {
              height: 100%;
            }
          }

          &_text {
            width: calc(100% - 110px);
            padding-left: $space-5;
          }

          &.bottom {
            align-items: flex-end;

            .plan-feature_list-item_content_img {
              height: auto;

              img {
                height: auto;
              }
            }
          }
        }

        .c-btn-boxArrow {
          margin-bottom: $space-1;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  //フィーチャーエリア
  &-set {
    position: relative;
    padding-bottom: 150px;
  }
}

/*--------------------------------------------------
カレンダー領域のスタイル
--------------------------------------------------*/
.calendar {
  position: relative;
  width: 100%;
  z-index: 1;

  &_inner {
    padding: 40vw 0 55vw;
    background-color: rgba($gray, 0.5);

    @include mq-up(lg) {
      padding: 200px 0;
    }
  }

  &_content {
    position: relative;
  }

  &_head {
    margin-bottom: $space-1;
  }

  &_head-image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: $space-5;

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &_notion-list {
    position: relative;
  }

  &_notion-item {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-size: $size-5;
    font-weight: 500;

    &::before {
      position: absolute;
      content: '※';
      top: 0;
      left: 0;
    }
  }

  &_main-list {
    position: relative;
  }

  &_main-item {
    margin-bottom: $space-1;

    &:last-of-type {
      margin-bottom: 0;
    }

    &_image {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: $space-5;

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &_catch {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: $space-6;
      font-size: $size-5;
      font-weight: 700;

      &-strong {
        position: relative;
        display: inline-flex;
        margin: 0 3px;
        font-size: $size-4;

        &::before {
          position: absolute;
          content: '';
          bottom: 7px;
          left: 0;
          display: block;
          width: 100%;
          height: 7px;
          background-color: palettes(accent, lighter);
          z-index: -1;
        }

        &.has-notion {
          padding-right: 15px;

          &::after {
            position: absolute;
            content: '※';
            top: 0;
            right: 0;
            font-size: 0.7em;
          }
        }
      }
    }
  }

  &_detail-list {
    position: relative;
    margin-bottom: $space-6;
  }

  &_detail-item {
    position: relative;
    display: flex;
    margin-bottom: $space-7;
    font-size: $size-5;
    font-weight: 700;

    &_dot {
      display: block;
      width: 20px;
      height: 20px;
      margin-top: $space-7;
      margin-right: 7px;
      border-radius: 50%;

      &.is-color-primary {
        background-color: palettes(primary, lighter);
      }

      &.is-color-secondary {
        background-color: palettes(secondary, lighter);
      }

      &.is-color-accent {
        background-color: palettes(accent, lighter);
      }

      &.is-color-pink {
        background-color: $pink;
      }

      &.is-color-gray {
        background-color: palettes(gray, lighter);
      }

      &.is-color-black {
        background-color: $black;
      }

      &.is-color-white {
        background-color: $white;
      }

      &.is-color-blue {
        background-color: #bdc6e0;
      }

      &.is-color-green {
        background-color: #b0cbad;
      }

      &.is-color-yellow {
        background-color: #f7d6a5;
      }

      &.has-border {
        border: 1px solid palettes(gray, dark);
      }
    }

    &_text {
      display: inline-flex;
      align-items: flex-end;
      flex-wrap: wrap;

      &-strong {
        margin: 0 3px;
        font-size: 1.1em;
        color: $accent;
      }
    }
  }

  &_table {
    position: relative;
    height: 300px;
    width: 100%;
    margin-bottom: $space-2;
    overflow: scroll;

    table {
      position: relative;
      text-align: center;
      font-size: $size-5;

      tbody {
        position: relative;
      }

      th {
        position: relative;
        background-color: $white;
        border: 1px solid $primary;
        font-weight: 700;

        &.none {
          opacity: 0;
          border: none;
        }

        &.month {
          background-color: rgba($gray, 0.8);
          border: 1px solid $white;
          border-right: 0px;

          div {
            padding: 0 $space-5;
            word-break: keep-all;
          }
        }
      }

      td {
        background-color: $white;
        border: 1px solid $primary;

        div {
          display: flex;
          width: 600px;
          padding: $space-5 $space-5 0 $space-5;
          font-weight: 700;

          &:last-child {
            padding-bottom: $space-5;
          }

          .label {
            display: inline-block;
            height: fit-content;
            min-width: fit-content;
            padding: 0 $space-6;
            margin-right: $space-5;
            border-radius: 3px;
            background-color: $primary;
            color: $white;
            text-align: left;
            font-weight: 400;
          }

          .text-red {
            color: $accent;
          }

          .text-blue {
            color: #0033cc;
          }
        }
      }
    }
  }

  &_notion {
    position: relative;

    &_title {
      position: relative;
      display: inline-block;
      padding: 0 $space-6;
      margin-bottom: $space-5;
      background-color: $primary;
      border-radius: 5px;
      color: $white;
      line-height: $size-l;
    }

    &_list {
      &-item {
        position: relative;
        padding-left: 20px;
        padding-bottom: $space-6;
        font-size: $size-5;

        &::before {
          position: absolute;
          content: '・';
          top: 0;
          left: 0;
        }
      }
    }
  }
}

/*--------------------------------------------------
お食事領域のスタイル
--------------------------------------------------*/
.meal {
  position: relative;
  width: 100%;
  margin-top: -55vw;
  background: rgba($gray, 0.3) url(../img/00_common/main-bg01.png) repeat left top/contain;

  @include mq-up(lg) {
    margin-top: -200px;
  }

  &_inner {
    padding-top: 55vw;

    @include mq-up(lg) {
      padding-top: 200px;
    }
  }

  &_slide {
    position: relative;
    padding-bottom: $space-1;
    margin-bottom: $space-xl;

    &_list {
      &-item {
        position: relative;
        height: 300px;

        @include mq-up(md) {
          height: 500px;
        }

        @include mq-up(lg) {
          height: 300px;
        }

        &_text {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          width: 100%;
          height: 100%;
          z-index: 1;

          span {
            padding-left: 20px;
            padding-bottom: 20px;
            font-size: $size-4;
            font-weight: 700;
            line-height: 1;
            color: $white;
          }
        }

        &_img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        &_bg {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, transparent 70%, $black 120%);
          z-index: 0;
        }
      }
    }
  }

  &_content {
    position: relative;

    &_title {
      display: inline-block;
      padding: $space-6 $space-3;
      margin-bottom: $space-3;
      background-color: rgba($secondary, 0.7);
      border-radius: 5px;
      font-size: $size-3;
      font-weight: 700;
    }

    &_lead {
      padding-bottom: $space-3;
      text-align: justify;
    }

    &_list {
      position: relative;
      margin-bottom: $space-1;

      &-item {
        position: relative;
        padding-bottom: $space-1;

        &:last-child {
          padding-bottom: 0;
        }

        .name {
          display: inline-block;
          width: 100%;
          padding-left: $space-5;
          margin-bottom: $space-3;
          background-color: $primary;
          color: $white;
        }

        .text {
          .red {
            color: $accent;
          }
        }

        .price {
          margin-bottom: $space-1;
          font-weight: 500;
          border-bottom: 1px solid $gray;

          span {
            font-size: $size-1;
          }
        }

        .course {
          position: relative;

          &_title {
            display: inline-block;
            padding: 0 $space-6;
            margin-bottom: $space-4;
            background-color: rgba($gray, 0.3);
            border: 1px solid $primary;
            border-radius: 5px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

/*--------------------------------------------------
フロー領域のスタイル
--------------------------------------------------*/
.flow {
  position: relative;
  width: 100%;

  &_content {
    position: relative;
    padding-bottom: $space-1;
  }

  &_list {
    position: relative;
    margin-top: $space-1;

    &-item {
      position: relative;
      background-color: #f5c1b5;
      border-radius: 15px;
      padding: $space-xl $space-4 $space-3;
      margin-bottom: $space-xl;

      &:last-child {
        margin-bottom: 0;
      }

      &_icon {
        position: absolute;
        top: -40px;
        width: 100px;
      }

      &_title {
        padding-bottom: $space-3;
        font-size: $size-3;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: $primary;
        text-shadow: 1.5px 1.5px 0 $white, -1.5px -1.5px 0 $white, -1.5px 1.5px 0 $white, 1.5px -1.5px 0 $white,
          0px 1.5px 0 $white, 0 -1.5px 0 $white, -1.5px 0 0 $white, 1.5px 0 0 $white;
      }

      &_text {
        padding-bottom: $space-3;
      }

      &_notion {
        position: relative;
        padding-bottom: $space-3;

        &-item {
          position: relative;
          padding-left: 20px;
          font-size: $size-5;

          &::before {
            position: absolute;
            content: '※';
            top: 0;
            left: 0;
          }
        }
      }

      &_img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &_body {
          width: 250px;
        }
      }
    }
  }
}

/*--------------------------------------------------
FAQ領域のスタイル
--------------------------------------------------*/
.faq {
  position: relative;
  width: 100%;

  &_list {
    position: relative;
    display: flex;
    flex-direction: column;

    &-item {
      padding-bottom: $space-1;

      &:last-child {
        padding-bottom: 0;
      }

      &_title {
        display: inline-block;
        width: 100%;
        padding: $space-3 0 $space-2;
        font-size: $size-4;
        font-weight: 700;
        text-align: center;
      }

      dt,
      dd {
        display: block;
        padding: $space-5;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 10px 10px 20px rgba($black, 0.3);
      }

      dt {
        display: block;
        width: 100%;
        margin-bottom: $space-5;

        span {
          position: relative;
          display: inline-block;
          padding-left: $space-4;
          font-weight: 500;

          &::before {
            position: absolute;
            content: 'Q';
            top: 0;
            left: 0;
          }
        }
      }

      dd {
        display: block;
        margin-left: $space-4;
        margin-bottom: $space-3;

        span {
          position: relative;
          display: inline-block;
          padding-left: $space-4;
          color: rgba($black, 0.5);

          &::before {
            position: absolute;
            content: 'A';
            top: 0;
            left: 0;
          }
        }
      }

      &_notion {
        position: relative;
        padding-top: $space-5;
        padding-left: $space-4;

        li {
          position: relative;
          padding-left: 20px;
          font-size: $size-5;
          color: rgba($black, 0.5);

          &::before {
            position: absolute;
            content: '※';
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

/*--------------------------------------------------
ギャラリー領域のスタイル
--------------------------------------------------*/
.gallery {
  position: relative;
  width: 100%;

  &_notion {
    position: relative;
    padding: $space-5 $space-4;
    margin-bottom: $space-l;
    border: 1px solid $secondary;
    border-radius: 15px;
    color: $secondary;
  }

  &_content {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-template-rows: 200px 200px 200px;
    width: 100vw;
    margin-left: calc(((100vw - 100%) / 2) * -1);
    margin-right: calc(((100vw - 100%) / 2) * -1);
    margin-bottom: $space-l;

    @include mq-up(md) {
      grid-template-rows: 400px 400px 400px;
    }

    @include mq-up(lg) {
      grid-template-rows: 200px 200px 200px;
      width: 400px;
      margin-left: -25px;
      margin-right: -25px;
    }

    &_img {
      width: 100%;
      height: 100%;
    }
  }
}

/*--------------------------------------------------
アクセス領域のスタイル
--------------------------------------------------*/
.access {
  position: relative;
  width: 100%;
  padding-bottom: 300px;

  &_map {
    position: relative;
    width: 100%;
    height: 350px;

    iframe {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &_info {
    position: relative;
    padding-top: $space-2;

    &_title {
      padding-bottom: $space-3;
      font-size: 3.5rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      color: $primary;
    }

    &_list {
      position: relative;
      padding: $space-5 0;

      &-item {
        position: relative;
        padding-left: 20px;

        &::before {
          position: absolute;
          content: '・';
          top: 0;
          left: 0;
        }
      }
    }
  }
}

/*--------------------------------------------------
追従フォームリンクのスタイル
--------------------------------------------------*/
.form-link {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $primary;
  transition: all 0.3s;

  &:hover {
    background-color: $white;
    transition: all 0.3s;

    .form-link_inner i,
    .form-link_inner span {
      color: $primary;
      transition: all 0.3s;
    }
  }

  &_inner {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: fit-content;

    i {
      font-size: $size-2;
      color: $white;
      transition: all 0.3s;
    }

    span {
      padding-left: $space-5;
      color: $white;
      transition: all 0.3s;
    }
  }

  &.sp {
    display: block;
    width: 100%;
    padding: $space-5 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.3s;
    z-index: 9998;

    @include mq-up(lg) {
      display: none;
    }
  }

  &.is-removed {
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
  }

  &.pc {
    display: none;

    @include mq-up(lg) {
      display: block;
      width: fit-content;
      padding: $space-5 $space-3;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px solid $primary;
    }
  }
}

/*--------------------------------------------------
フッターのスタイル
--------------------------------------------------*/
.footer {
  position: relative;
  width: 100%;
  background-color: $accent;
  padding: $space-1 0;

  &_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: $space-5 $space-4;
  }

  &_logo {
    width: 200px;
    padding: $space-3 0;
    margin: 0 auto;
  }

  &_content {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: $space-3 auto;
  }

  &-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: $space-3;

    &_title {
      color: $white;
      font-size: $size-5;
    }

    &_link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: $space-6;
      padding: $space-5;
      background-color: $white;
      border-radius: 5px;
      border: 1px solid $primary;
      font-size: $size-4;
      font-weight: 500;
      color: $primary;
      text-align: center;
      transition: all 0.3s;

      &:hover {
        background-color: $primary;
        color: $white;
        transition: all 0.3s;
      }

      i {
        font-size: $size-3;
      }
    }

    &_tel {
      width: 100%;
      margin-top: $space-6;
      border-bottom: 1px solid rgba($primary, 0.5);

      .number {
        font-size: $size-1;
        font-weight: 700;
        color: $white;
      }

      i {
        font-size: $size-1;
      }
    }

    &_notion {
      padding-top: $space-5;
      color: $white;
      font-size: $size-6;
    }
  }

  &_copy {
    &_text {
      color: $primary;
      font-size: $size-6;
    }
  }
}

/*--------------------------------------------------
タブレットサイズ以上の時の背景のスタイル
--------------------------------------------------*/
.background {
  display: none;

  @include mq-up(lg) {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    background: url(../img/00_common/bg-main-pattern.webp) no-repeat center center/cover;
    z-index: 1;
  }
}

.background-devider {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -2;
}

.background-left {
  position: relative;
  width: calc(((100vw - 400px) / 2) + 10%);
  height: 100%;
  z-index: -1;

  @include mq-up(xl) {
    width: calc(((100vw - 400px) / 2) + 15%);
  }

  &_inner {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &_content {
    &-top {
      position: relative;
      display: flex;
    }
  }

  &_logo {
    width: 250px;
    margin: 12vh 0 0 6vw;

    img {
      height: auto;
    }
  }

  &_img-top {
    width: 500px;
    margin-top: -3vw;
    overflow: hidden;

    img {
      height: auto;
    }
  }

  &_img-bottom {
    width: 43vw;
    max-width: 800px;
    margin-top: -5vw;
    margin-left: -100px;
    overflow: hidden;

    img {
      height: auto;
    }
  }

  &_shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: -50px 0 50px -50px rgba($black, 0.35) inset;
    z-index: 0;
  }
}

.background-right {
  position: relative;
  width: calc(((100vw - 400px) / 2) - 10%);
  height: 100%;
  background: $accent;
  box-shadow: 50px 0 50px -50px rgba($black, 0.35) inset;
  z-index: 999;

  @include mq-up(xl) {
    width: calc(((100vw - 400px) / 2) - 15%);
  }

  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-left: $space-1;
  }

  &_title {
    font-size: $size-2;
    font-weight: 500;
    color: $primary;
    letter-spacing: 0.1em;

    @include mq-up(xl) {
      font-size: $size-1;
    }
  }

  &_list {
    padding-top: $space-1;

    &-item {
      padding-bottom: $space-5;
    }
  }
}
