html {
  height: 100%;
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: $family-primary;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 2;
  font-weight: 300;
  min-height: 100vh;
  color: $primary;
}

main {
  flex: 1;
}

a {
  color: $primary;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: rgba($primary, 0.6);
    transition: all 0.3s;
  }
}

button {
  appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  touch-action: manipulation;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
